import { env } from '../../local-env';
const apiBaseURL = env.API_URL;
import axiosHttp from 'axios'

export const axios = axiosHttp.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')!==null&&localStorage.getItem('token')!=undefined?localStorage.getItem('token')||null:null}`,    
    Accept : 'application/json',
    Location : localStorage.getItem('location'),
    Pragma: 'no-cache'
  }
})
